import Button from 'components/ui/Button';
import Drawer from 'components/Drawer';
import GeneralInfo from 'compositions/GeneralInfo';
import Head from 'next/head';
import VimeoPlayer from 'components/VimeoPlayer';
import { imageUrl, sanityFetch } from 'api';
import { General } from 'type/general';
import { setGeneral } from 'store/generalSlice';
import { useAppDispatch } from 'store/hooks';
import { useEffect, useState } from 'react';
import type { GetStaticProps, NextPage } from 'next';
import { useTranslation } from 'hooks/useTranslation';
import LanguageSelector from 'compositions/LanguageSelector';
import { useGoogleTagManager } from '@grace-studio/graceful-next/hooks';

type HomePageProps = {
  general: General;
};

const HomePage: NextPage<HomePageProps> = ({ general }) => {
  const dispatch = useAppDispatch();
  const translation = useTranslation();
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const gtm = useGoogleTagManager();

  useEffect(() => {
    dispatch(setGeneral(general));
  }, [general, dispatch]);

  return (
    <>
      <Head>
        {general?.seo?.title && <title>{general.seo.title}</title>}
        {general?.seo?.description && (
          <meta name="description" content={general.seo.description} />
        )}
        {general?.seo?.shareImage && (
          <meta
            property="og:image"
            content={imageUrl(general.seo.shareImage).toString()}
          />
        )}
      </Head>

      <GeneralInfo title={general.home.title} text={general.home.preamble}>
        {general.infoVideo.showVideoButton && (
          <Button
            color="blue"
            style="outline"
            className="row-start-2 md:row-start-1"
            onClick={() => {
              setIsVideoOpen(true);
              gtm.trackEvent({
                event: 'btn_open_video',
              });
            }}
          >
            {translation('home.learnMore')}
          </Button>
        )}
        <Button
          url={'/room'}
          color="blue"
          className="col-start-1 md:col-start-2 hidden sm:inline-flex"
        >
          {translation('home.selectRoomType')}
        </Button>
      </GeneralInfo>

      <LanguageSelector />

      {general.infoVideo.showVideoButton && (
        <Drawer
          position="center"
          transition="fade"
          isOpen={isVideoOpen}
          backdropClassName="bg-black/[.6]"
          onClose={() => {
            setIsVideoOpen(false);
            setIsVideoPlaying(false);
          }}
        >
          <div className="aspect-video w-[400px] md:w-[700px] lg:w-[900px]">
            <VimeoPlayer
              paused={!isVideoPlaying}
              onPlay={() => setIsVideoPlaying(true)}
              onPause={() => setIsVideoPlaying(false)}
              id={general.infoVideo.vimeoId}
              title={general.infoVideo.title}
              posterImageSrc={imageUrl(
                general.infoVideo.posterImage,
              ).toString()}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};

export const getStaticProps: GetStaticProps<HomePageProps> = async (
  context,
) => {
  const lang = context.locale || 'en';

  const query = `*[_type == "general" && __i18n_lang == "${lang}"]{...,home{...,"roomLink":roomLink->slug.current}}`;
  const [general] = await sanityFetch<General[]>(query);

  return {
    props: { general },
  };
};

export default HomePage;
